
// @ts-nocheck
import { Component, Vue, Watch } from 'vue-property-decorator';
import SellerHeader from '@/components/Seller/SellerHeader.vue';
import { ErrorMessages } from '@/helpers/constants';
import { getAssistantData } from '@/services/seller.service';
import { userYear, eventDate, eventDateTime, idoDateToDateAndHours } from '@/helpers/date';

@Component({
  components: {
    SellerHeader,
  },
})
export default class AssistantProfile extends Vue {
  eventId!: number = localStorage.getItem('selectedEvent');
  assistantId = '';
  assistant = '';
  eventDetails = '';
  public skeletonCount = [0, 1, 2, 3];
  loading = false;

  formatDate(isoDate: string) {
    return idoDateToDateAndHours(isoDate);
  }

  getPaymentMethod(method) {
    if (method === 'transfer') {
      return 'Transferencia';
    } else if (method === 'cash') {
      return 'Efectivo';
    } else if (method === 'card') {
      return 'Tarjeta';
    }
  }

  protected redirect(route: string): void {
    window.location.href = process.env.VUE_APP_DOMAIN_ECOMMERCE + route;
  }

  async mounted() {
    this.assistantId = this.$route.params.id;
    try {
      this.loading = true;
      const data = await getAssistantData(this.assistantId);
      this.assistant = data.data.data;
      this.eventDetails = this.assistant.events.find(
        event => event.id == this.$route.params.eventId,
      );

      this.loading = false;
    } catch (e) {
      Vue.$toast.error(ErrorMessages.COMMON);
    }
  }
}
